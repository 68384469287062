import { CmsWidgetHeadline } from './cms-widget-headline';

export type CmsWidgetStepsItem = {
  readonly typoHeading: string;
  readonly typoBodyHtml: string;
};

export type CmsWidgetSteps = {
  readonly headline: CmsWidgetHeadline;
  readonly steps: CmsWidgetStepsItem[];
  readonly _type: '_CmsWidgetSteps';
};

export function isCmsWidgetSteps(input: unknown): input is CmsWidgetSteps {
  return (input as any)?.['_type'] === '_CmsWidgetSteps';
}

function createCmsWidgetStepsItem(json: Record<string, any>): CmsWidgetStepsItem {
  return {
    typoHeading: json['title'],
    typoBodyHtml: json['plainHtml']
  };
}

export function createCmsWidgetStepsData(json: Record<string, any>): CmsWidgetSteps {
  return {
    headline: {
      badgeTagTitle: json['label'],
      typoHeading: json['title'],
      typoBodyHtml: json['plainHtml']
    },
    steps: json['items'].map(createCmsWidgetStepsItem),
    _type: '_CmsWidgetSteps'
  };
}
