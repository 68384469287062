import { CmsActionButton, createCmsActionButtonData } from './cms-action-button';
import { CmsWidgetHeadline } from './cms-widget-headline';

export type CmsWidgetCard = {
  readonly type: 'games' | undefined;
  readonly collectionId: string | undefined;
  readonly typoHeading: string;
  readonly typoBodyHtml: string;
  readonly actionButton: CmsActionButton[] | undefined;
  readonly imageUrl: string;
};

export type CmsWidgetCards = {
  readonly headline: CmsWidgetHeadline;
  readonly cards: CmsWidgetCard[];
  readonly _type: '_CmsWidgetCards';
};

export function isCmsWidgetCards(input: unknown): input is CmsWidgetCards {
  return (input as any)?.['_type'] === '_CmsWidgetCards';
}

function createCmsWidgetCards(json: Record<string, any>): CmsWidgetCard {
  return {
    type: 'games',
    collectionId: json['collectionID'] ?? undefined,
    typoHeading: json['title'],
    typoBodyHtml: json['plainHtml'],
    actionButton: json['buttons'] ? createCmsActionButtonData(json['buttons']) : undefined,
    imageUrl: json['bgImage']
  };
}

export function createCmsWidgetCardsData(json: Record<string, any>): CmsWidgetCards {
  return {
    headline: {
      badgeTagTitle: json['label'],
      typoHeading: json['title'],
      typoBodyHtml: json['plainHtml']
    },
    cards: json['items'].map(createCmsWidgetCards),
    _type: '_CmsWidgetCards'
  };
}
