import { CmsActionButton, createCmsActionButtonData } from './cms-action-button';

export type CmsWidgetBanner = {
  readonly badgeTagTitle: string;
  readonly typoHeading: string;
  readonly typoBodyHtml: string;
  readonly imageUrl: string;
  readonly contentPosition: 'left' | 'right';
  readonly actionButton: CmsActionButton[] | undefined;
  readonly _type: '_CmsWidgetBanner';
};

export function isCmsWidgetBanner(input: unknown): input is CmsWidgetBanner {
  return (input as any)?.['_type'] === '_CmsWidgetBanner';
}

export function createCmsWidgetBannerData(json: Record<string, any>): CmsWidgetBanner {
  return {
    badgeTagTitle: json['label'] ?? undefined,
    typoHeading: json['title'],
    typoBodyHtml: json['plainHtml'] ?? undefined,
    imageUrl: json['image'],
    contentPosition: json['imagePosition'],
    actionButton: json['buttons'] ? createCmsActionButtonData(json['buttons']) : undefined,
    _type: '_CmsWidgetBanner'
  };
}
