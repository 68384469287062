import { appStyles, appVariables } from '@bcflit-css/janusz-casino/mobile/app.styles';
import { provideSvgIconAppComputedStylesService } from '@bcflit-ds-components/base/svg-icon/svg-icon-app-computed-styles.service';
import { EnvTma } from '@bcflit-env/types';
import { provideCookiesConsentService } from '@bcflit-v1-ui-shared/cookies-consent/cookies-consent.service';
import { provideGtmNotifierService } from '@bcflit-v1-ui-shared/gtm/gtm-notifier.service';
import { provide } from '@lit/context';
import { authState } from '@tmf-logic-auth/services/auth-state';
import { ImportedElement, TmfLitElement, registerElement } from '@tmf-shared-classes/tmf-lit-element';
import {
  ActivatedBottomSheet,
  activatedBottomSheetContext
} from '@tmf-shared-platform/activated-bottom-sheet/activated-bottom-sheet';
import { ActivatedLang, activatedLang, activatedLangContext } from '@tmf-shared-platform/activated-lang/activated-lang';
import { ActivatedModal, activatedModalContext } from '@tmf-shared-platform/activated-modal/activated-modal';
import {
  ActivatedRoute,
  activatedRouteContext,
  provideActivatedRoute
} from '@tmf-shared-platform/activated-route/activated-route';
import {
  ActivatedUrl,
  activatedUrlContext,
  provideActivatedUrl
} from '@tmf-shared-platform/activated-url/activated-url';
import { HistoryUrl, historyUrlContext } from '@tmf-shared-platform/history-url/history-url';
import { Router } from '@tmf-shared-platform/router/internal';
import {
  RouterNavigatorService,
  provideRouterNavigatorService
} from '@tmf-shared-platform/router/router-navigator.service';
import { routerPageScrollerService } from '@tmf-shared-platform/router/router-page-scroller.service';
import { routerUpdateUrl } from '@tmf-shared-platform/router/router-utils';
import { ScrollRestorer, scrollRestorerContext } from '@tmf-shared-platform/scroll-restorer/scroll-restorer';
import { provideAffiliatesDataCatcher } from '@tmf-shared-services/affiliates-data-catcher';
import { checkCountryBlockage } from '@tmf-shared-services/check-country-blockage';
import { CmsSeoJsonLdUpdaterSmartElement } from '@tmf-ui-cms-casino-components-janusz-casino-mobile/cms-seo-json-ld-updater-smart/cms-seo-json-ld-updater-smart.element';
import { CmsSeoMetaUpdaterSmartElement } from '@tmf-ui-cms-casino-components-janusz-casino-mobile/cms-seo-meta-updater-smart/cms-seo-meta-updater-smart.element';
import { GtmLoaderElement } from '@tmf-ui-misc-casino-components-janusz-casino-mobile/gtm-loader/gtml-loader.element';
import { userSettingsI18nSegment } from '@tmf-ui-routes/i18n-segments/user-settings.i18n-segment';
import { homeRoutePath } from '@tmf-ui-routes/paths/home.route-path';
import { routerContext } from '@tmf-ui-routes/router-context';
import { SharedSettings, sharedSettings, sharedSettingsContext } from '@tmp-shared-settings/settings/shared-settings';
import { CSSResult, TemplateResult, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { combineLatest, first } from 'rxjs';
import { appRoutes } from '../../mobile/app/app-routes';

export async function boostrapApplication(env: EnvTma): Promise<void> {
  sharedSettings.env = env;
  (await import('../../mobile/app/app-init')).appInit();
  class TmfApp extends TmfLitElement {
    public static override styles: CSSResult[] = [appVariables, appStyles];
    protected override _importedElements: ImportedElement[] = [
      CmsSeoMetaUpdaterSmartElement,
      CmsSeoJsonLdUpdaterSmartElement,
      GtmLoaderElement
    ];

    @provide({ context: activatedLangContext })
    @property({ attribute: false })
    public activatedLang: ActivatedLang = activatedLang;

    @provide({ context: activatedRouteContext })
    @property({ attribute: false })
    public activatedRoute: ActivatedRoute = provideActivatedRoute();

    @provide({ context: activatedModalContext })
    @property({ attribute: false })
    public activatedModal: ActivatedModal = new ActivatedModal(this.activatedRoute);

    @provide({ context: activatedBottomSheetContext })
    @property({ attribute: false })
    public activatedBottomSheet: ActivatedBottomSheet = new ActivatedBottomSheet(this.activatedRoute);

    @provide({ context: activatedUrlContext })
    @property({ attribute: false })
    public activatedUrl: ActivatedUrl = provideActivatedUrl();

    @provide({ context: historyUrlContext })
    @property({ attribute: false })
    public historyUrl: HistoryUrl = new HistoryUrl();

    @provide({ context: scrollRestorerContext })
    @property({ attribute: false })
    public scrollRestorer: ScrollRestorer = new ScrollRestorer();

    @provide({ context: sharedSettingsContext })
    @property({ attribute: false })
    public sharedSettings: SharedSettings = sharedSettings;

    @provide({ context: routerContext })
    @property({ attribute: false })
    public router: Router = new Router(
      this,
      appRoutes(() => this.router, sharedSettings.env.langs)
    );

    @state()
    private _shouldShowCountryBlockage: boolean = false;

    public override connectedCallback(): void {
      super.connectedCallback();
      const routerNavigatorService: RouterNavigatorService = provideRouterNavigatorService();
      routerNavigatorService.bindRouter(this.router);
      routerNavigatorService.bindHistoryUrl(this.historyUrl);
      routerPageScrollerService.init();
      provideCookiesConsentService().init();
      provideGtmNotifierService().init();
      provideAffiliatesDataCatcher().init(this.activatedRoute);
      this._checkCountryBlockage();
      this._checkUserPanelAuthGuard();
      this.activatedRoute.addRouter(this.router);
      provideSvgIconAppComputedStylesService().setComputedStyles(getComputedStyle(this));
    }

    private _checkCountryBlockage(): void {
      checkCountryBlockage.shouldShowBlockagePage().subscribe((shouldShow: boolean) => {
        this._shouldShowCountryBlockage = shouldShow;
        import(
          '@tmf-ui-misc-casino-components-janusz-casino-mobile/not-available-in-your-country/not-available-in-your-country.element'
          // eslint-disable-next-line @typescript-eslint/typedef
        ).then((mod) => registerElement(mod.NotAvailableInYourCountryElement));
      });
    }

    private _checkUserPanelAuthGuard(): void {
      combineLatest([authState.isLoggedIn(), this.activatedUrl.url$])
        .pipe(first(([isLoggedIn, url]: [boolean, string]) => !isLoggedIn && url.includes(userSettingsI18nSegment())))
        .subscribe(() => routerUpdateUrl(this.router, homeRoutePath()));
    }

    public override render(): TemplateResult {
      return html`
        <tmf-jzm-cms-seo-json-ld-updater-smart></tmf-jzm-cms-seo-json-ld-updater-smart>
        <tmf-jzm-cms-seo-meta-updater-smart></tmf-jzm-cms-seo-meta-updater-smart>
        <tmf-jzm-gtm-loader .gtmId="${'GTM-KV8K6H4H'}"></tmf-jzm-gtm-loader>
        ${when(
          this._shouldShowCountryBlockage,
          () => html`<tmf-jzm-not-available-in-your-country></tmf-jzm-not-available-in-your-country>`,
          () => html`${this.router.outlet()}`
        )}
      `;
    }
  }
  customElements.define('tmf-app', TmfApp);
}
