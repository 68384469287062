import { device } from '@bcflit-device';
import { resolveUrlWithVersionNumber } from '@tmf-logic-api-access/common/resolve-url-with-version-number';
import { CmsBonus, createCmsBonusesFromHttpData } from '@tmf-shared-models/cms-bonus';
import { CmsCasinoCategory, createCmsCasinoCategoriesFromHttpData } from '@tmf-shared-models/cms-casino-category';
import {
  CmsCountry,
  createCmsCountryBlockedFromHttpData,
  createCmsCountryFromHttpData
} from '@tmf-shared-models/cms-country';
import { CmsFooterData, createCmsFooterDataFromHttpData } from '@tmf-shared-models/cms-footer-data';
import { CmsGameSeoContent, createCmsGameSeoContentFromHttpData } from '@tmf-shared-models/cms-game-seo-content';
import { CmsPaymentSettings, createCmsPaymentSettingsFromHttpData } from '@tmf-shared-models/cms-payment-settings';
import { CmsPromotion, createCmsPromotionsFromHttpData } from '@tmf-shared-models/cms-promotion';
import { CmsPromotionDetail, createCmsPromotionDetailFromHttpData } from '@tmf-shared-models/cms-promotion-details';
import { CmsPromotionSlide, createCmsPromotionSlidesFromHttpData } from '@tmf-shared-models/cms-promotion-slide';
import {
  CmsSeoSettings,
  CmsSeoSettingsConfig,
  createCmsSeoSettingsConfigFromHttpData,
  createCmsSeoSettingsFromHttpData
} from '@tmf-shared-models/cms-seo-settings';
import { CmsStaticAffiliates, createCmsStaticAffiliatesData } from '@tmf-shared-models/cms-static-affiliates';
import { CmsStaticGeneric, createCmsStaticGenericFromHttpData } from '@tmf-shared-models/cms-static-generic';
import { CmsStaticSupport, createCmsStaticSupportFromHttpData } from '@tmf-shared-models/cms-static-support';
import { ActivatedLangConst, activatedLangConst } from '@tmf-shared-platform/activated-lang/activated-lang-const';
import { StaticsKind } from '@tmf-ui-shared/misc/enums';
import { SharedSettings, sharedSettings } from '@tmp-shared-settings/settings/shared-settings';
import { Observable, first, lastValueFrom, map, mergeMap, shareReplay } from 'rxjs';
import { HttpClient, httpClient } from './http-client';

export class CmsApi {
  private _getCmsSeoSettingsShareRef$!: Observable<CmsSeoSettings | undefined>;

  constructor(
    private _sharedSettings: SharedSettings,
    private _httpClient: HttpClient,
    private _activatedLangConst: ActivatedLangConst
  ) {}

  private get _cmsApiUrl(): string {
    return resolveUrlWithVersionNumber(this._sharedSettings.env.cmsApiUrl);
  }

  public async getStaticsGeneric(staticsKind: StaticsKind): Promise<CmsStaticGeneric> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/statics?filters%5BslugName%5D%5B$eq%5D=${staticsKind}&populate%5Bcontent%5D%5Bpopulate%5D=*&locale=${this._activatedLangConst.lang}`
    );
    return createCmsStaticGenericFromHttpData(response);
  }

  public async getStaticsSupport(): Promise<CmsStaticSupport> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/support?locale=${this._activatedLangConst.lang}`
    );
    return createCmsStaticSupportFromHttpData(response);
  }

  public async getPromotionsSlides(): Promise<CmsPromotionSlide[]> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/main-sliders?populate%5BsliderContent%5D%5Bpopulate%5D&populate%5Bimage%5D%5Bfields%5D=url&populate%5Bcategory%5D%5Bfields%5D=categoryName&populate%5Bdates%5D%5Bpopulate%5D&populate%5Bbuttons%5D%5Bpopulate%5D&populate%5BshortTerms%5D%5Bpopulate%5D&populate%5BimageMobile%5D%5Bfields%5D=url&locale=${this._activatedLangConst.lang}`
    );

    return createCmsPromotionSlidesFromHttpData(response, device);
  }

  public async getFooterData(): Promise<CmsFooterData> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/footer?populate=*&locale=${this._activatedLangConst.lang}`
    );

    return createCmsFooterDataFromHttpData(response);
  }
  public async getPromotions(): Promise<CmsPromotion[]> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/promotions?populate%5BimageUrl%5D%5Bfields%5D%5B0%5D=url&populate%5Bcategories%5D%5Bfields%5D%5B0%5D=categoryName&locale=${this._activatedLangConst.lang}`
    );

    return createCmsPromotionsFromHttpData(response);
  }

  public async getPromotionDetails(slugName: string): Promise<CmsPromotionDetail | undefined> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/promotions?filters%5BslugName%5D%5B$eq%5D=${slugName}&populate=*&locale=${this._activatedLangConst.lang}`
    );

    return createCmsPromotionDetailFromHttpData(response);
  }

  public async getBonuses(): Promise<CmsBonus[]> {
    const response: [Record<string, any>] = await this._httpClient.get(
      `${this._cmsApiUrl}api/bonuses-2-0?locale=${this._activatedLangConst.lang}`
    );
    return createCmsBonusesFromHttpData(response);
  }

  public async getSlotsViewCategories(): Promise<CmsCasinoCategory[]> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/game-collections?filters%5Bpage%5D%5B$eq%5D=slots&populate=*&locale=${this._activatedLangConst.lang}`
    );
    return createCmsCasinoCategoriesFromHttpData(response);
  }

  public async getHomeViewCategories(): Promise<CmsCasinoCategory[]> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/game-collections?filters%5Bpage%5D%5B$eq%5D=home&populate=*&locale=${this._activatedLangConst.lang}`
    );
    return createCmsCasinoCategoriesFromHttpData(response);
  }

  public async getLiveCasinoViewCategories(): Promise<CmsCasinoCategory[]> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/game-collections?filters%5Bpage%5D%5B$eq%5D=live-casino&populate=*&locale=${this._activatedLangConst.lang}`
    );
    return createCmsCasinoCategoriesFromHttpData(response);
  }

  public async getGamesProvidersViewCategories(): Promise<CmsCasinoCategory[]> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/game-collections?filters%5Bpage%5D%5B$eq%5D=games-providers&populate=*&locale=${this._activatedLangConst.lang}`
    );
    return createCmsCasinoCategoriesFromHttpData(response);
  }

  public async getDepositSettings(): Promise<CmsPaymentSettings[]> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/deposits?populate%5B0%5D=formFields.fields.cryptocurrency.currencies&populate%5B1%5D=formFields.fields.fieldRules.rules&populate=image.url&locale=${this._activatedLangConst.lang}`
    );
    return createCmsPaymentSettingsFromHttpData(response);
  }

  public async getWithdrawSettings(): Promise<CmsPaymentSettings[]> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/withdraws?populate%5B0%5D=formFields.fields.cryptocurrency.currencies&populate%5B1%5D=formFields.fields.fieldRules.rules&populate=image.url&locale=${this._activatedLangConst.lang}`
    );
    return createCmsPaymentSettingsFromHttpData(response);
  }

  public async getCmsGameSeoContent(slugName: string): Promise<CmsGameSeoContent | undefined> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/games-seo-contents?filters%5Bslug%5D=${slugName}&fields%5B0%5D=gameName&fields%5B1%5D=descriptionHtml&fields%5B2%5D=descriptionExtendedHtml&locale=${this._activatedLangConst.lang}`
    );
    return createCmsGameSeoContentFromHttpData(response);
  }

  public async getSearchTags(): Promise<string[]> {
    const response: Record<string, any> = await this._httpClient.get(`${this._cmsApiUrl}api/search-tag?populate=*`);
    return response['data']['attributes']['Tags'].map((inner: Record<string, any>) => inner['tagName']);
  }

  public async getCmsCountries(): Promise<CmsCountry[]> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/country-dropdown-list?populate=*&locale=${this._activatedLangConst.lang}`
    );
    return createCmsCountryFromHttpData(response);
  }

  public async getCmsBlockedCountries(): Promise<CmsCountry[]> {
    const response: Record<string, any> = await this._httpClient.get(`${this._cmsApiUrl}api/block-countrie?populate=*`);
    return createCmsCountryBlockedFromHttpData(response);
  }

  public async cmsNotify404(path: string): Promise<void> {
    await this._httpClient.post(`${this._cmsApiUrl}api/404-monitor?locale=${this._activatedLangConst.lang}`, {
      url: path
    });
    return;
  }

  public async getStaticAffiliates(slugName: string): Promise<CmsStaticAffiliates> {
    const response: Record<string, any> = await this._httpClient.get(
      `${this._cmsApiUrl}api/affiliates-2-0/${slugName}?locale=${this._activatedLangConst.lang}`
    );
    return createCmsStaticAffiliatesData(response);
  }

  public async getCmsSeoSettings(): Promise<CmsSeoSettings | undefined> {
    return lastValueFrom(
      (this._getCmsSeoSettingsShareRef$ ??= this._activatedLangConst.getLang().pipe(
        first(),
        mergeMap((lang: string) =>
          this._httpClient.get<Record<string, any>>(`${this._cmsApiUrl}api/seo-settings?locale=${lang}`)
        ),
        map(createCmsSeoSettingsFromHttpData),
        shareReplay(1)
      ))
    );
  }

  public async getCmsOpenGameSeoSettings(slugName: string): Promise<CmsSeoSettingsConfig | undefined> {
    return lastValueFrom(
      this._activatedLangConst.getLang().pipe(
        first(),
        mergeMap((lang: string) =>
          this._httpClient.get<Record<string, any>>(
            `${this._cmsApiUrl}api/seo-settings/single?locale=${lang}&slugName=${slugName}`
          )
        ),
        map(createCmsSeoSettingsConfigFromHttpData)
      )
    );
  }
}

export const cmsApi: CmsApi = new CmsApi(sharedSettings, httpClient, activatedLangConst);
