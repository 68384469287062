import { CmsWidgetHeadline } from './cms-widget-headline';

export type CmsWidgetStepsHorizontalItemIcon = {
  name: string;
  fill1: 'brand-60' | 'brand' | 'neutral-30';
};

export type CmsWidgetStepsHorizontalItem = {
  readonly typoHeading: string;
  readonly typoBodyHtml: string;
  readonly typoTitle: string;
  readonly icon: string;
};

export type CmsWidgetStepsHorizontal = {
  readonly headline: CmsWidgetHeadline;
  readonly steps: CmsWidgetStepsHorizontalItem[];
  readonly _type: '_CmsWidgetStepsHorizontal';
};

export function isCmsWidgetStepsHorizontal(input: unknown): input is CmsWidgetStepsHorizontal {
  return (input as any)?.['_type'] === '_CmsWidgetStepsHorizontal';
}

function createCmsWidgetStepsHorizontalItem(json: Record<string, any>): CmsWidgetStepsHorizontalItem {
  return {
    icon: json['icon'],
    typoHeading: json['title'],
    typoBodyHtml: json['plainHtml'],
    typoTitle: json['name']
  };
}

export function createCmsWidgetStepsHorizontalData(json: Record<string, any>): CmsWidgetStepsHorizontal {
  return {
    headline: {
      badgeTagTitle: json['label'],
      typoHeading: json['title'],
      typoBodyHtml: json['plainHtml']
    },
    steps: json['items'].map(createCmsWidgetStepsHorizontalItem),
    _type: '_CmsWidgetStepsHorizontal'
  };
}
