import { CmsWidgetBanner, createCmsWidgetBannerData } from './cms-widget-banner';
import { CmsWidgetCards, createCmsWidgetCardsData } from './cms-widget-cards';
import { CmsWidgetGamesCollection, createCmsWidgetGamesCollectionData } from './cms-widget-games-collection';
import { CmsWidgetIconsGroup, createCmsWidgetIconsGroupData } from './cms-widget-icons-group';
import { CmsWidgetMainBanner, createCmsWidgetMainBannerData } from './cms-widget-main-banner';
import { CmsWidgetSteps, createCmsWidgetStepsData } from './cms-widget-steps';
import { CmsWidgetStepsHorizontal, createCmsWidgetStepsHorizontalData } from './cms-widget-steps-horizontal';

export type CmsStaticAffiliatesConfigurableKind =
  | 'games'
  | 'banner'
  | 'payments'
  | 'providers'
  | 'grid'
  | 'testimonials';

export type CmsStaticAffiliatesContent = {
  readonly kind: CmsStaticAffiliatesConfigurableKind;
  readonly games: CmsWidgetGamesCollection | undefined;
  readonly payments: CmsWidgetIconsGroup | undefined;
  readonly providers: CmsWidgetIconsGroup | undefined;
  readonly banner: CmsWidgetBanner | undefined;
  readonly cards: CmsWidgetCards | undefined;
  readonly testimonials: CmsWidgetStepsHorizontal | undefined;
};

export type CmsStaticAffiliates = {
  readonly slugName: string;
  readonly isRegisterFormVisible: boolean;
  readonly mainBanner: CmsWidgetMainBanner;
  readonly steps: CmsWidgetSteps;
  readonly terms: string;
  readonly content: CmsStaticAffiliatesContent[];
};

export function createCmsStaticAffiliatesContent(contentJson: Record<string, any>): CmsStaticAffiliatesContent {
  return {
    kind: contentJson['kind'],
    games:
      (contentJson['kind'] as CmsStaticAffiliatesConfigurableKind) === 'games'
        ? createCmsWidgetGamesCollectionData(contentJson['games'])
        : undefined,
    banner:
      (contentJson['kind'] as CmsStaticAffiliatesConfigurableKind) === 'banner'
        ? createCmsWidgetBannerData(contentJson['banner'])
        : undefined,
    providers:
      (contentJson['kind'] as CmsStaticAffiliatesConfigurableKind) === 'providers'
        ? createCmsWidgetIconsGroupData(contentJson['providers'])
        : undefined,
    payments:
      (contentJson['kind'] as CmsStaticAffiliatesConfigurableKind) === 'payments'
        ? createCmsWidgetIconsGroupData(contentJson['payments'])
        : undefined,
    cards:
      (contentJson['kind'] as CmsStaticAffiliatesConfigurableKind) === 'grid'
        ? createCmsWidgetCardsData(contentJson['grid'])
        : undefined,
    testimonials:
      (contentJson['kind'] as CmsStaticAffiliatesConfigurableKind) === 'testimonials'
        ? createCmsWidgetStepsHorizontalData(contentJson['testimonials'])
        : undefined
  };
}

export function createCmsStaticAffiliatesData(json: Record<string, any>): CmsStaticAffiliates {
  return {
    slugName: json['slugName'],
    isRegisterFormVisible: json['isRegisterFormVisible'],
    mainBanner: createCmsWidgetMainBannerData(json['hero']),
    steps: createCmsWidgetStepsData(json['steps']),
    terms: json['longTerms'],
    content: json['content'].map(createCmsStaticAffiliatesContent)
  };
}
