export type CmsWidgetIconsGroup = {
  readonly icons: string[];
  readonly _type: '_CmsWidgetIconsGroup';
};

export function isCmsWidgetIconsGroup(input: unknown): input is CmsWidgetIconsGroup {
  return (input as any)?.['_type'] === '_CmsWidgetIconsGroup';
}

export function createCmsWidgetIconsGroupData(json: string[]): CmsWidgetIconsGroup {
  return {
    icons: json,
    _type: '_CmsWidgetIconsGroup'
  };
}
