import { CmsWidgetHeadline } from './cms-widget-headline';

export type CmsWidgetGamesCollection = {
  readonly headline: CmsWidgetHeadline;
  readonly collectionId: string;
  readonly _type: '_CmsWidgetGamesCollection';
};

export function isCmsWidgetGamesCollection(input: unknown): input is CmsWidgetGamesCollection {
  return (input as any)?.['_type'] === '_CmsWidgetGamesCollection';
}

export function createCmsWidgetGamesCollectionData(json: Record<string, any>): CmsWidgetGamesCollection {
  return {
    headline: {
      badgeTagTitle: json['label'],
      typoHeading: json['title'],
      typoBodyHtml: json['plainHtml']
    },
    collectionId: json['collectionID'],
    _type: '_CmsWidgetGamesCollection'
  };
}
